import React from 'react';
import { Link } from 'react-router-dom';
import { RiFileExcel2Fill, RiFilePdfFill, RiLoader2Line } from 'react-icons/ri';
import dates from '~/utils/dates';
import numbers from '~/utils/numbers';
import './styles.css';

// // Components
import Admin from '~/components/Admin';
import Loading from '~/components/Default/Loading';
import { Cative, MercadoLivre } from '~/components/Parts/CalculatesBox';
import { useSingleReport } from './useSingle';

const Report: React.FC<any> = () => {
    const {
        ready,
        unit,
        unitCalc,
        downReady,
        downPDFReady,
        yearMonth,
        months,
        monthCurr,
        statusReq,
        exportPDF,
        exportExcel,
        changeMonth,
    } = useSingleReport();

    function renderMessageError(statusRequest: number): JSX.Element {
        switch (statusRequest) {
          case 212:
                return (
                <>
                    <p className="alert">
                        Seu relatório de economia foi gerado manualmente e já está disponível em nosso sistema
                    </p>
                    <small>
                        Confira na aba <b>Documentos</b> localizado no menu
                        ou <Link to="/documentos">clique aqui</Link> para visualizá-lo agora mesmo!
                    </small>
                </>
            );
            case 213:
            case 214:
                return (
                <>
                    <p className="alert">
                    Seu relatório de economia não pode ser gerado devido à falta de dados no sistema
                    </p>
                    <small>
                        Confira na aba <b>Documentos</b> localizado no menu
                        ou <Link to="/documentos">clique aqui</Link> para visualizá-lo agora mesmo!
                    </small>
                </>
            );
          default:
                return (
                <>
                    <p className="alert">
                        Nenhuma fatura localizada para o período selecionado
                    </p>
                    <small>
                        Faça o upload para exibir esse relatório,
                        ou <Link to="/contas">clique aqui</Link> para fazer o upload da sua fatura.
                    </small>
                </>
            )
        }
    }

    function calculateEconomy(cativeEconomy: number, freeMarketEconomy:number){
        let result = cativeEconomy - freeMarketEconomy
        let porcentage = ((result)/unitCalc.cativo.totalFlag)*100
        return (numbers.format((porcentage), true, 2) + '%')

    }

    return (
        <Admin
            pageTitle="Relatório - Comparativo de Economia do Mercado Cativo x Mercado Livre de Energia - Clientes Serena"
            title="Relatório de Economia Cativo x Livre"
            classMain='report'>
            {
                ready ?
                    unitCalc && unitCalc.cativo && unitCalc.mercadoLivre ? (
                        <>
                            <div className="header">
                                <div className='title'>
                                    <h1>{unit.name}</h1>
                                    <p>Cliente desde: {unit ? dates.getDateFormatted(unit.init_contract, 'dd/mm/yyyy') : '-'}</p>
                                </div>

                                <div className="buttons">
                                    {
                                        downReady ?
                                        <button className="btnExcel" onClick={() => {exportExcel()}}>
                                            <RiFileExcel2Fill size="15" color="#FFFFFF" /> <span>Exportar Excel</span>
                                        </button> :
                                        <button className="btnLoad">
                                            <RiLoader2Line size="15" color="#FFFFFF" /> <span>Aguarde</span>
                                        </button>
                                    }

                                    {
                                        downPDFReady ?
                                        <button className="btnPDF" onClick={() => {exportPDF()}}>
                                            <RiFilePdfFill size="15" color="#FFFFFF" /> <span>Exportar PDF</span>
                                        </button> :
                                        <button className="btnLoad">
                                            <RiLoader2Line size="15" color="#FFFFFF" /> <span>Aguarde</span>
                                        </button>
                                    }

                                    <div className="months">
                                        <p>Mês de referência</p>
                                        <select
                                            name="month"
                                            id="month"
                                            onChange={changeMonth}
                                            value={yearMonth ? yearMonth : monthCurr.year + '-' + monthCurr.month}
                                        >
                                            <optgroup label="Mês / Ano">
                                                {
                                                    months.map(el => (el.year!==monthCurr.year || el.month<=monthCurr.month) &&
                                                        <option
                                                            key={`mes-ano_${el.year + '-' + el.month}`}
                                                            value={ el.year + '-' + el.month }>
                                                            { el.name + ' de ' + el.year }
                                                        </option>
                                                    )
                                                }
                                            </optgroup>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div id="print">
                                <div className="headerLine">
                                    <div className="provider">
                                        <b>Distribuidora:</b> { unitCalc.data.provider.name } <i>- { unitCalc.data.power } - { unitCalc.data.modality }</i>
                                    </div>
                                    <div className="typeEnergy">
                                        <b>Energia no Mercado Livre:</b> { unitCalc.data.type==='inc' ? 'Incentivada' : 'Convencional' }
                                    </div>
                                </div>

                                <div className="savings">
                                    <div
                                        className={ `economy
                                            ${(unitCalc.cativo.totalFlag-unitCalc.mercadoLivre.total) < 0 ? ' neg' : ''}` }
                                    >
                                        <div className="label">
                                            Economia com o Mercado Livre (R$)
                                        </div>
                                        <div className="value">
                                            { numbers.money(unitCalc.cativo.totalFlag-unitCalc.mercadoLivre.total, false) }
                                        </div>
                                    </div>

                                    <div className={ `economy${(unitCalc.cativo.totalFlag-unitCalc.mercadoLivre.total)<0 ? ' neg' : ''}` }>
                                        <div className="label">
                                            Economia com o Mercado Livre (%)
                                        </div>
                                        <div className="value">
                                            {calculateEconomy(unitCalc.cativo.totalFlag, unitCalc.mercadoLivre.total)}
                                        </div>
                                    </div>
                                </div>

                                <div className="calculateBox">
                                    <Cative data={unitCalc.data} calc={unitCalc.cativo} />
                                    <hr />
                                    <MercadoLivre data={unitCalc.data} calc={unitCalc.mercadoLivre} />
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="alert-center">
                            { renderMessageError(statusReq) }
                            <div className="monthsBox">
                                <b>Alterar o mês de referência</b>
                                <select
                                    name="month"
                                    id="month"
                                    onChange={changeMonth}
                                    value={yearMonth ? yearMonth : monthCurr.year + '-' + monthCurr.month}
                                >
                                    <optgroup label="Mês / Ano">
                                        {
                                            months.map(el => (el.year!==monthCurr.year || el.month<=monthCurr.month) && (
                                                <option
                                                    key={`mes-ano_${el.year + '-' + el.month}`}
                                                    value={ el.year + '-' + el.month }>
                                                    { el.name + ' de ' + el.year }
                                                </option>
                                            ))
                                        }
                                    </optgroup>
                                </select>
                            </div>
                        </div>
                ) :
                <Loading />
            }

        </Admin>
    )
}

export default Report;