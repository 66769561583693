import React, { ChangeEvent, useEffect, useState, useContext } from 'react';
import { FaSave } from 'react-icons/fa';
import sort_by from '~/utils/sort';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext, ModalContext } from '~/contexts';

// Components
import { Checkbox } from '~/components/Parts/Forms';

interface Props {
    units: any;
    data: any;
    setReadyPage: Function;
};

const FormEdit: React.FC<Props> = ({units, data, setReadyPage}): any => {
    const [mailer, setMailer] = useState<any>(null);

    // Context
    const {user} = useContext(GeneralContext);
    const Modal  = useContext(ModalContext);

    function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const {name, value} = event.target;
        let infos: any = {[name]: value};

        setMailer({...mailer, ...infos});
    }

    function onChangeCheckbox(event: ChangeEvent<HTMLInputElement>) {
        const {name, checked} = event.target;
        let data: any = {[name]: (checked ? 1 : 0)};

        setMailer({...mailer, ...data});
    }

    function saveData() {
        const mailerData = mailer ? mailer : {};

        api.post('mailers/create', mailerData, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setReadyPage(false);
            Modal.setModalOpen(false);
        }).catch(err => {
            const { status } = err.response;

            if (status===400) {
                const error = err.response.data.errors;
                console.log(error);
            }
        });
    }

    function cancel () {
        Modal.setModalOpen(false);
    }

    // Reder Select
    function renderUnits() {
        if (units.length>1) {
            return <select
                name="unit" id="unit" onChange={onChange}
                defaultValue={mailer && mailer.unit ? mailer.unit : undefined}
            >
                <option value="">-- Selecione --</option>
                <optgroup label="Unidades">
                    {
                        units.map((el: any) => (
                            <option
                                key={`unit_${el.id}`}
                                value={el.id}
                            >
                                {el.name}
                            </option>
                        ))
                    }
                </optgroup>
            </select>
        }

        // Single Unit
        return <input id="unit_mask" className='no-select' name="unit_mask" type="text" placeholder="Unidade" value={units[0].name} disabled />
    }

    // Set Mailer Data
    useEffect(() => {
        !mailer && data && setMailer({ ...data, unit: String(data.unit.id) })
    }, [mailer])

    return units.length>0 && mailer && (
        <>
            <div className="mailer_body">
                <div className="line">
                    <div className="name">
                        <label htmlFor="name">Nome</label>
                        <input id="name" name="name" type="text" placeholder="Nome" value={mailer ? mailer.name : ''} onChange={onChange} />
                    </div>

                    <div className="mail">
                        <label htmlFor="mail">Email</label>
                        <input id="mail" name="mail" type="text" placeholder="Email" value={mailer ? mailer.mail : ''} onChange={onChange} />
                    </div>
                </div>

                <div className="line">
                    <div className="unit">
                        <label htmlFor="unit">Unidade</label>
                        { renderUnits() }
                    </div>
                </div>

                <div className="line">
                    <div className="report center">
                        <label htmlFor="report">Relatórios</label>
                        <Checkbox id='report' name='economy_report' checked={mailer.economy_report ? 1 : 0} onChange={onChangeCheckbox} />
                    </div>

                    <div className="sales_off center">
                        <label htmlFor="sales_off">Garantia Fincanceira</label>
                        <Checkbox id='sales_off' name='ccee_sales_off' checked={mailer.ccee_sales_off ? 1 : 0} onChange={onChangeCheckbox} />
                    </div>

                    <div className="bkp_energy center">
                        <label htmlFor="bkp_energy">Energia de Reserva</label>
                        <Checkbox id='bkp_energy' name='ccee_backup_power' checked={mailer.ccee_backup_power ? 1 : 0} onChange={onChangeCheckbox} />
                    </div>

                    <div className="ccee_fees center">
                        <label htmlFor="ccee_fees">Taxa CCEE</label>
                        <Checkbox id='ccee_fees' name='ccee_fees' checked={mailer.ccee_fees ? 1 : 0} onChange={onChangeCheckbox} />
                    </div>

                    <div className="ccee_ercap center">
                        <label htmlFor="ccee_ercap">Taxa ERCAP</label>
                        <Checkbox id='ccee_ercap' name='ccee_ercap' checked={mailer.ccee_ercap ? 1 : 0} onChange={onChangeCheckbox} />
                    </div>
                </div>
            </div>

            <div className="footerBottons">
                <button className="btn save" onClick={() => saveData()}><FaSave /></button>
                <button className="btn cancel" onClick={() => cancel()}>Cancelar</button>
            </div>
        </>
    );
}

export default FormEdit;