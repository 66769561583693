import React from 'react';
import numbers from '~/utils/numbers';
import treatNotes from './treatNotes';
import './style.css';

// Refs: ¹²³⁴⁵⁶⁷⁸⁹

interface Props {
    data: any;
    calc: any;
}

const MercadoLivreBox: React.FC<Props> = (props) => {
    const data   = props.data;
    const calc   = props.calc;

    // Init treat notes
    treatNotes.init(data, calc);

    return (
        <div className="freeMarket">
            <h2>Custo do Mercado Livre</h2>

            <div className="body">
                <div className="line title">
                    <div className="label"></div>
                    <div className="qtty">Quant.</div>
                    <div className="mult">Tarifas</div>
                    <div className="value">Valor</div>
                </div>

                {
                    data.modality==='Verde' ? (
                        <>
                            <div className="line">
                                <div className="label">Demanda Única (KW)</div>
                                <div className="qtty">{ numbers.format(data.demands.consumed.offPeak) }</div>
                                <div className="mult">{ numbers.format(calc.demandUniqueMult, true, 5) }</div>
                                <div className="value">{ numbers.money(calc.demandUnique, false) }</div>
                            </div>

                            { data.demands.notConsumed.offPeak !== 0 &&
                                <div className="line">
                                    <div className="label">Demanda Única Sem ICMS (KW)</div>
                                    <div className="qtty">{ numbers.format(data.demands.notConsumed.offPeak) }</div>
                                    <div className="mult">{ numbers.format(calc.demandUniqueMult, true, 5) }</div>
                                    <div className="value">{ numbers.money(calc.demandNoIcmsUnique, false) }</div>
                                </div>
                            }

                            { data.demands.overtaking.offPeak!==0 &&
                                <div className="line">
                                    <div className="label">Ultrapassagem de demanda única (KW)</div>
                                    <div className="qtty">{ numbers.format(data.demands.overtaking.offPeak) }</div>
                                    <div className="mult">{ numbers.format(calc.demandOvertakingUniqueMult, true, 5) }</div>
                                    <div className="value">{ numbers.money(calc.demandOvertakingUnique, false) }</div>
                                </div>
                            }
                        </>
                    ) : (
                        <>
                            <div className="line">
                                <div className="label">Demanda Ponta (KW)</div>
                                <div className="qtty">{ numbers.format(data.demands.consumed.peak) }</div>
                                <div className="mult">{ numbers.format(calc.demandPeakMult, true, 5) }</div>
                                <div className="value">{ numbers.money(calc.demandPeak, false) }</div>
                            </div>

                            <div className="line">
                                <div className="label">Demanda Fora Ponta (KW)</div>
                                <div className="qtty">{ numbers.format(data.demands.consumed.offPeak) }</div>
                                <div className="mult">{ numbers.format(calc.demandOffPeakMult, true, 5) }</div>
                                <div className="value">{ numbers.money(calc.demandOffPeak, false) }</div>
                            </div>

                            <div className="line">
                                <div className="label">Demanda Ponta Sem ICMS (KW)</div>
                                <div className="qtty">{ numbers.format(data.demands.notConsumed.peak) }</div>
                                <div className="mult">{ numbers.format(calc.demandPeakMult, true, 5) }</div>
                                <div className="value">{ numbers.money(calc.demandPeakNoICMS, false) }</div>
                            </div>

                            <div className="line">
                                <div className="label">Demanda Fora Ponta Sem ICMS (KW)</div>
                                <div className="qtty">{ numbers.format(data.demands.notConsumed.offPeak) }</div>
                                <div className="mult">{ numbers.format(calc.demandOffPeakMult, true, 5) }</div>
                                <div className="value">{ numbers.money(calc.demandOffPeakNoICMS, false) }</div>
                            </div>

                            { data.demands.overtaking.peak !== 0 &&
                                <div className="line">
                                    <div className="label">Ultrapassagem de Demanda Ponta (KW)</div>
                                    <div className="qtty">{ numbers.format(data.demands.overtaking.peak) }</div>
                                    <div className="mult">{ numbers.format(calc.demandOvertakingPeakMult, true, 5) }</div>
                                    <div className="value">{ numbers.money(calc.demandOvertakingPeak, false) }</div>
                                </div>
                            }

                            { data.demands.overtaking.offPeak !== 0 &&
                                <div className="line">
                                    <div className="label">Ultrapassagem de Demanda Fora Ponta (KW)</div>
                                    <div className="qtty">{ numbers.format(data.demands.overtaking.offPeak) }</div>
                                    <div className="mult">{ numbers.format(calc.demandOvertakingOffPeakMult, true, 5) }</div>
                                    <div className="value">{ numbers.money(calc.demandOvertakingOffPeak, false) }</div>
                                </div>
                            }
                        </>
                    )
                }

                <div className="line">
                    <div className="label">TUSD Encargos Ponta (KWh)</div>
                    <div className="qtty">{ numbers.format(data.consumptionPeak) }</div>
                    <div className="mult">{ numbers.format(calc.consumptionPeakMult, true, 5) }</div>
                    <div className="value">{ numbers.money(calc.consumptionPeak, false) }</div>
                </div>

                <div className="line">
                    <div className="label">TUSD Encargos Fora Ponta (KWh)</div>
                    <div className="qtty">{ numbers.format(data.consumptionOffPeak) }</div>
                    <div className="mult">{ numbers.format(calc.consumptionOffPeakMult, true, 5) }</div>
                    <div className="value">{ numbers.money(calc.consumptionOffPeak, false) }</div>
                </div>

                <div className={`line${!calc.teACL ? ' line-alert' : ''}`}>
                    <div className="label">TE ACL (KWh){!calc.teACL ? ' ³' : ''}</div>
                    <div className="qtty">{ numbers.format(data.teAcl ? data.teAcl : data.consumptionTE) }</div>
                    {
                        calc.teACL ? (
                            <>
                                <div className="mult">{ numbers.format(data.price, true, 5) }</div>
                                <div className="value">{  numbers.money(calc.teACL, false) }</div>
                            </>
                        ) : (
                            <div className="value plus" dangerouslySetInnerHTML={{ __html: '<span class="default">Dados ainda não disponíveis na CCEE</span><span class="mobile">N/D</span>' }}></div>
                        )
                    }
                </div>

                <div className={`line${!calc.perdaTrans ? ' line-alert' : ''}`}>
                    <div className="label">Perdas de Transmissão ({numbers.format(data.lostEnergy, true, 2)}%){!calc.perdaTrans ? ' ³' : ''}</div>
                    <div className="qtty">{ numbers.format(calc.perdaTransQtty) }</div>
                    {
                        calc.perdaTrans ? (
                            <>
                                <div className="mult">{ numbers.format(data.price, true, 5) }</div>
                                <div className="value">{  numbers.money(calc.perdaTrans, false) }</div>
                            </>
                        ) : (
                            <div className="value plus" dangerouslySetInnerHTML={{ __html: '<span class="default">Dados ainda não disponíveis na CCEE</span><span class="mobile">N/D</span>' }}></div>
                        )
                    }
                </div>

                <div className={`line${data.proinfa>=0 ? '' : ' line-alert'}`}>
                    <div className="label">Energia Proinfa</div>
                    <div className="qtty">{ numbers.format(data.proinfa, false) }</div>
                    {
                        data.proinfa>=0 ? (
                            <>
                                <div className="mult">{ numbers.format(data.price, true, 5) }</div>
                                <div className="value">{ (data.proinfa>0 ? '-' : '') + numbers.format(calc.proinfa, true, 2) }</div>
                            </>
                        ) : (
                            <div className="value plus" dangerouslySetInnerHTML={{ __html: '<span class="default">Dados ainda não disponíveis na CCEE</span><span class="mobile">N/D</span>' }}></div>
                        )
                    }
                </div>

                <div className="line">
                    <div className="label">Base de Cálculo PIS/COFINS (R$)</div>
                    <div className="qtty"></div>
                    <div className="mult"></div>
                    <div className="value">{ numbers.money(calc.calcPisCofins, false) }</div>
                </div>

                <div className="line">
                    <div className="label">Base de Cálculo ICMS (R$)</div>
                    <div className="qtty"></div>
                    <div className="mult"></div>
                    <div className="value">{ numbers.money(calc.calcIcms, false) }</div>
                </div>

                <div className="line">
                    <div className="label">PIS/COFINS</div>
                    <div className="qtty"></div>
                    <div className="mult">{ numbers.format(data.pisCofins, true) }%</div>
                    <div className="value">{ numbers.money(calc.pisCofins, false) }</div>
                </div>

                <div className="line">
                    <div className="label">ICMS</div>
                    <div className="qtty"></div>
                    <div className="mult">{ numbers.format(data.icms, true) }%</div>
                    <div className="value">{ numbers.money(calc.icms, false) }</div>
                </div>

                <div className={`line ${calc.liqFinc===null && ' line-alert'}`}>
                    <div className="label">Liquidação Financeira</div>
                    <div className="qtty"></div>
                    <div className="mult"></div>
                    {
                        calc.liqFinc===null ?
                        <div className="value plus" dangerouslySetInnerHTML={{ __html: '<span class="default">Não informado</span><span class="mobile">N/D</span>' }}></div> :
                        <div className="value">{ numbers.money(calc.liqFinc, false) }</div>
                    }
                </div>

                <div className="line">
                    <div className="label">Energia de Reserva</div>
                    <div className="qtty"></div>
                    <div className="mult"></div>
                    <div className="value">{ numbers.money((calc.bkpPower===null ? calc.bkpPowerPrev : calc.bkpPower), false) }</div>
                </div>

                {calc.ccee !== 0 &&
                    <div className="line">
                        <div className="label">Taxa da CCEE</div>
                        <div className="qtty"></div>
                        <div className="mult"></div>
                        <div className="value">{ numbers.money(calc.ccee, false) }</div>
                    </div>
                }

                {calc.ccee_ercap !== 0 &&
                    <div className="line">
                        <div className="label">Taxa da ERCAP</div>
                        <div className="qtty"></div>
                        <div className="mult"></div>
                        <div className="value">{ numbers.money(calc.ccee_ercap, false) }</div>
                    </div>
                }

                {
                    calc.feeInterestCorrection !== 0 &&
                    <div className="line">
                        <div className="label">Multa, Juros e Correção (R$)</div>
                        <div className="qtty"></div>
                        <div className="mult"></div>
                        <div className="value">{ numbers.money(calc.feeInterestCorrection, false) }</div>
                    </div>
                }

                {
                    calc.discounts !== 0 &&
                    <div className="line">
                        <div className="label">Descontos (R$)</div>
                        <div className="qtty"></div>
                        <div className="mult"></div>
                        <div className="value"> -{ numbers.money(calc.discounts, false) }</div>
                    </div>
                }

                {
                    data.others.freeMarket!==0 && (
                        <div className="line">
                            <div className="label">Outros Valores (R$)</div>
                            <div className="qtty"></div>
                            <div className="mult"></div>
                            <div className="value">{ numbers.money(data.others.freeMarket, false) }</div>
                        </div>
                    )
                }

                {
                    data.covid !== 0 && (
                        <div className="line">
                            <div className="label">Conta COVID (R$)</div>
                            <div className="qtty"></div>
                            <div className="mult"></div>
                            <div className="value">{ numbers.money(data.covid, false) }</div>
                        </div>
                    )
                }

                <div className="line total">
                    <div className="label">Total</div>
                    <div className="qtty"></div>
                    <div className="mult"></div>
                    <div className="value">{ numbers.money(calc.total, false) }</div>
                </div>
            </div>
        </div>
    );
}

export default MercadoLivreBox;