import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { GeneralContext, ModalContext, ThemeContext } from '~/contexts';
import numbers from '~/utils/numbers';
import './styles.css';

// Components
import UserBox from '../User';
import MyUnits from './units';

// Images
import logo from '~/assets/images/logo_serena.svg';
import logoDark from '~/assets/images/logo_serena_white.svg';
import gold from '~/assets/images/budgets/gold.png';
import silver from '~/assets/images/budgets/silver.png';
import bronze from '~/assets/images/budgets/bronze.png';

interface Props {
    title: any;
    subtitle: any;
    units?:any;
}

const Header: React.FC<Props> = (props) => {
    const [budget, setBudget] = useState<any>(null);

    // Contexts
    const modal     = useContext(ModalContext);
    const { theme } = useContext(ThemeContext);
    const { unit }  = useContext(GeneralContext);
    const ecoTotal  = unit && unit.economy>0 ? numbers.money(unit.economy, false).split(',') : null;

    function openUnits () {
        modal.setModalTitle('Minhas Unidades')
        modal.setModalBody(<MyUnits/>)
        modal.setModalOpen(true)
    }

    function openModal () {
        modal.setModalTitle('Aviso')
        modal.setModalBody(<div>O sistema foi desenvolvido para desktop. Recomendamos que abra novamente em um desktop.</div>)
        modal.setModalOpen(true)
    }

    function mobile(){
            const screenWidth = window.innerWidth;

            if (screenWidth < 719) {
                openModal ()
            }
    }

    useEffect(() => {
        mobile()
        if(unit){
            let reference = unit.cost_reference && unit.cost_reference>0 ? unit.cost_reference : null;
            let bronzeRef = unit.cost_reference*2;
            let silverRef = unit.cost_reference*5;
            let goldRef   = unit.cost_reference*9;

            if (reference && unit.economy>bronzeRef && unit.economy<silverRef) {
                setBudget({name: 'Bronze', icon: bronze});
            } else if (reference && unit.economy>silverRef && unit.economy<goldRef) {
                setBudget({name: 'Prata', icon: silver});
            } else if (reference && unit.economy>goldRef) {
                setBudget({name: 'Ouro', icon: gold});
            }
        }
    }, [])

    return (
        <header>
            <div className="logo">
                <Link to='/'>
                    <img src={theme==='light' ? logo : logoDark} alt="Serena"/>
                </Link>
            </div>

            <div className="section">
                <h1>
                    { props.title }
                    { props.subtitle ? <small>&nbsp;&nbsp;-&nbsp;&nbsp;{ props.subtitle }</small> : <></> }
                </h1>

                <div className="infos">
                    <p className='unit-name'>{unit?.name ?? ''}</p>
                    <button className='content-info' onClick={openUnits}>
                        <p> Minhas unidades </p>
                    </button>

                    {
                        budget ? <>
                            <div className='content-info'>
                                <div className='economy'>
                                    <p><small>Economia total</small></p>
                                    {ecoTotal ? <p>R$ {ecoTotal[0]}<sup>,{ecoTotal[1]}</sup></p> : <p>-</p>}
                                </div>

                                <img alt='nível' src={ budget.icon } data-for="tooltip_economy_budget" data-tip={`Você está na categoria ${budget.name}`} />
                            </div>

                            <ReactTooltip
                                id="tooltip_economy_budget"
                                place="left"
                                effect="solid"
                                type="info"
                            />
                        </> : <></>
                    }

                    <UserBox />
                </div>
            </div>
        </header>
    )
}

export default Header;